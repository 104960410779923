import React from "react"
import Comment from './Comment.js';
import '../App.scss';

class WorkingHours extends React.Component {
  render() {
    return (
        <div className='section mt-80 invisible six'>
          <div className='section-header'>
            <div className="mr-32">Working hours</div>
            <div className="mt-12">↓</div>
          </div>

          <div className='section-item d-flex pt-12'>
            <div className="time w-120 subdued w-120 pr-32">2021 – today</div>
            <div className="description">
              <div>Principal Product Experience Designer</div>
              <div className="subdued pt-4"><a href="https://www.dynatrace.com/" target="_blank">Dynatrace</a> (Linz, Austria)</div>
              <div className="subdued pt-12">Currently, I'm responsible for crafting the platform experience of our new product iteration, which was launched in April 2023. You can somewhat think of this task as designing an operating system, much like MacOS or Windows.</div>
            </div>
          </div>

          <div className='section-item d-flex pt-40'>
            <div className="time w-120 subdued w-120 pr-32">2015 – 2021</div>
            <div className="description">
              <div>Senior Digital Product Designer & UX Lead</div>
              <div className="subdued pt-4"><a href="https://www.capgemini.com/" target="_blank">Capgemini</a> (Stuttgart, Germany)</div>
              <div className="subdued pt-12">An intrapreneurial journey of building and scaling an innovation lab and its team. Primary customer: Mercedes-Benz.</div>
            </div>
          </div>

          <div className='section-item d-flex pt-40'>
            <div className="time w-120 subdued w-120 pr-32">2014 – 2015</div>
            <div className="description">
              <div >Consulting Assistant (Enterprise Mobility)</div>
              <div className="subdued pt-4"><a href="https://mway.io/en/" target="_blank">mway</a> (Stuttgart, Germany)</div>
            </div>
          </div>

          <Comment text="I've led design teams and worked as an individual contributor in both small and large R&D teams/organizations, at early or mature stages, and in B2B or B2C contexts. My background in software engineering, design, and strategy provides me with a comprehensive view, enabling me to find the right balance among business goals, design intent, and technical constraints."/>

      </div>
    )
  }
}
export default WorkingHours