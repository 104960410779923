import React from "react";
import mslogo from '../ms_logo.png';
import '../App.scss';

class Footer extends React.Component {
  render() {
    return (
        <div className="footer mt-80 invisible nine">
          
          <img src={mslogo} style={{ height: '20px' }} alt="Logo of Marc Stauffer" />
          <div className="subdued"> © 2023 Marc Stauffer</div>

      </div>
    )
  }
}
export default Footer