import React from "react";
import mslogo from "../ms_logo.png";
import "../App.scss";

class Banner extends React.Component {
  render() {
    return (
      <div className="banner">
        <div className="banner-headline">
          <div>
            <img src={mslogo} className="profile-mobile mb-24 invisible" alt="Profile of Marc Stauffer" />
            <h1 className="text-display my-0 invisible two">
              Hey there, I'm Marc.
            </h1>
            <div className="text-display accent invisible three">
              Product designer.
            </div>
            <div className="text-display accent-2 invisible four">
              I value meaningful
              <br />
              software experiences
              <br />
              built by teams that care.
            </div>
          </div>
          <div className="social-links text-body subdued mt-20 invisible five">
            <div className="social-link">
              <a
                target="_blank"
                href="https://www.linkedin.com/in/marc-stauffer/"
              >
                LinkedIn
              </a>
            </div>
            <div className="social-link">
              <a target="_blank" href="https://twitter.com/marcstfx">
                Twitter
              </a>
            </div>
            <div className="social-link">
              <a target="_blank" href="https://dribbble.com/marcstfx">
                Dribbble
              </a>
            </div>
            <div className="social-link">
              <a target="_blank" href="https://medium.com/@marc.stauffer">
                Medium
              </a>
            </div>
            <div className="social-link">
              <a target="_blank" href="mailto:mail@marcstauffer.xyz">
                Email
              </a>
            </div>
          </div>
        </div>
        {/* <img src={profile} className="profile invisible one" alt="Profile of Marc Stauffer" /> */}
        <img
          src={mslogo}
          className="profile invisible"
          style={{ height: "48px", marginTop: "12px" }}
          alt="Logo of Marc Stauffer"
        />
      </div>
    );
  }
}
export default Banner;
