import React from "react"
import '../App.scss';
import avatar_small from '../avatar_small.png';

class Comment extends React.Component {
  render() {
    return (
        <div className="comment d-flex justify-center p-20 mt-40 surface" style={{ borderRadius: '16px' }}>
            <img src={avatar_small} className="avatar-small mr-16" alt="Avatar of author" />
            <span className="subdued">{this.props.text}</span>
        </div>
    )
  }
}
export default Comment