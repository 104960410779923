import React, { useEffect, useState } from 'react'
import '../App.scss';

const Header = () => {
    const [show, setShow] = useState(false)
    const controlHeader = () => {
        if (window.scrollY < 360) {
            setShow(false)
        } else {
            setShow(true)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', controlHeader)
        return () => {
            window.removeEventListener('scroll', controlHeader)
        }
    }, [])

    return (
        <div className={`header ${show && 'opacity'}`}>
            <div className='header-content'>
                <div className="subdued">Marc Stauffer</div>
                <div style={{ marginLeft: 'auto'}}>
                    <a target="_blank" className="pr-16" href="https://www.linkedin.com/in/marc-stauffer/">LinkedIn</a>
                    <a target="_blank" href="https://twitter.com/marcstfx">Twitter</a>
                </div>
            </div>
        </div>
    )
}

export default Header