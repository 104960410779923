import './App.scss';
import Header from './components/Header.js';
import Banner from './components/Banner.js';
import WorkingHours from './components/WorkingHours.js';
import Education from './components/Education.js';
import Freetime from './components/Freetime.js';
import Footer from './components/Footer.js';
import Blur from './components/Blur.js';

function App() {
  return (
    <div className="App">

      <div className='main-content'>
        <Header />
        <Banner />
        <WorkingHours />
        <Freetime />
        <Education />
        <Footer />
      </div>

    </div>
  );
}

export default App;
