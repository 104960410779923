import React from "react"
import Comment from './Comment.js';
import '../App.scss';

class Education extends React.Component {
  render() {
    return (
        <div className='section mt-80 invisible eight'>
        <div className='section-header'>
          <div className="mr-32">Education</div>
          <div className="mt-12">↓</div>
        </div>

        <div className='section-item d-flex pt-12'>
          <div className="time subdued w-120 pr-32">2020</div>
          <div className="description">
            <div>Nielsen Norman Group UX Certificate</div>
            <div className="subdued pt-4">Nielsen Norman Group</div>
          </div>
        </div>

        <div className='section-item d-flex pt-40'>
          <div className="time subdued w-120 pr-32">2019</div>
          <div className="description">
            <div>CP-UX Foundation</div>
            <div className="subdued pt-4">International Usability and UX Qualification Board e.V.</div>
          </div>
        </div>

        <div className='section-item d-flex pt-40'>
          <div className="time subdued w-120 pr-32">2015 – 2017</div>
          <div className="description">
            <div>Computer Science and Media (MSc.)</div>
            <div className="subdued pt-4">Stuttgart Media University</div>
          </div>
        </div>

        <div className='section-item d-flex pt-40'>
          <div className="time subdued w-120 pr-32">2012 – 2015</div>
          <div className="description">
            <div>Mobile Media (BSc.)</div>
            <div className="subdued pt-4">Stuttgart Media University</div>
          </div>
        </div>

        { /* <Comment text="I was part of the study commission for both study programs. After joining Capgemini, I was responsible for strengthening the university partnership and attracting talent. In this time, I supervised two master thesis (one around Design Sprints for voice products and one around VR/AR Design Systems). Passing on experience to passionate people is something I highly enjoy."/> */ }

      </div>
    )
  }
}
export default Education