import React from "react"
import '../App.scss';

class Freetime extends React.Component {
  render() {
    return (
        <div className='section mt-80 invisible seven'>
        <div className='section-header d-flex'>
          <div className="mr-32">Free time</div>
          <div className="mt-12">↓</div>
        </div>

        <div className='section-item d-flex pt-12'>
          <div className="time subdued w-120 pr-32">2021 – 2022</div>
          <div className="description">
          Engilada.io
            <div className="subdued pt-4">Engilada is an NFT engine that simplifies integrating Web3 features into any web project. From minting NFTs to managing ownership to implementing smart contracts, you need little Web3 knowledge upfront to focus on what matters: shipping value.</div>
          </div>
        </div>

        <div className='section-item d-flex pt-40'>
          <div className="time subdued w-120 pr-32">2020</div>
          <div className="description">
            <div><a target="_blank" href="https://magnwall.com/?lang=en"> MagnWall X smartdings </a></div>
            <div className="subdued pt-4">Designed a mobile app to control smart accessories for MagnWall, a startup that empowers businesses to create product displays (e.g. for shopping windows) without screws, grids, and wires. Magnetic accessories like backlighted shelves can be positioned anywhere on MagnWall surfaces and are powered instantly. This simplifies decoration, improves the reusability of accessories, and feels like magic.</div>
          </div>
        </div>

        <div className='section-item d-flex pt-40'>
          <div className="time subdued w-120 pr-32">2019</div>
          <div className="description">
          <a target="_blank" href="https://dribbble.com/shots/10993782-Atomic-journaling">Thght</a>
            <div className="subdued pt-4">Improve self-reflection through a minimalistic journaling approach by capturing only one thought a day.</div>
          </div>
        </div>

        <div className='section-item d-flex pt-40'>
          <div className="time subdued w-120 pr-32">2019</div>
          <div className="description">
          <a target="_blank" href="https://picktherightkite.com">Right Kite</a>
            <div className="subdued pt-4">A tiny tool I built to learn React. Enter a kitesurf location and get kite size recommendations based on weather conditions.</div>
          </div>
        </div>

        <div className='section-item d-flex pt-40'>
          <div className="time subdued w-120 pr-32">Now and then</div>
          <div className="description">
            <div>Writing</div>
            <div className="pt-4 subdued"><a target="_blank" href="https://blog.prototypr.io/how-to-learn-ui-design-what-my-first-two-years-as-a-digital-product-designer-taught-me-af6f2e31fc98">How to learn UI Design: what my first two years as a Digital Product Designer taught me</a></div>
            { /* <div className="pt-4 subdued"><a href="">Explore my old blog</a></div> */ }
          </div>
        </div>
      </div>
    )
  }
}
export default Freetime